body {
  /* font-family: "lohit-punjabi"; */
  src: url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("woff"),
    url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("opentype"),
    url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("truetype");
}


.heading_18{
  font-size: 18px;
    color: #040c29;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px !important;
    display: block;
}

.description{
  color: #767c90;
    font-size: 14px;
}

svg {
  cursor: pointer;
}

.form-select {
  cursor: pointer;
}

.font-500{
  font-weight: 500;
}

.font-600{
  font-weight: 600;
}

/* new records */
.as_zodiac_single_wrapper {
  background-color: #f2f2f2;
  color: #000000;
  /* margin-top: 73px; */
}

/* .as_zodiac_single_wrapper .astrologer_heading {
  padding-top: 95px;
} */

.as_zodiac_single_wrapper .astrologer_type .nav .nav-item a {
  padding: 15px 15px;
  color: #767c90;
  font-size: 18px;
  cursor: pointer;
}

.as_zodiac_single_wrapper .astrologer_type .nav .nav-item a.active {
  color: #886459;
  border-bottom: 2px solid #886459;
  font-weight: 500;
}

.as_zodiac_single_wrapper .border_bottom {
  border-bottom: 1px solid #b9bbbd;
  padding-bottom: 0;
}

.feed_comments_sec>ul>li p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
  margin-bottom: 0;
}

.feed_comments_sec>ul>li a.reply {
  font-size: 12px !important;
  color: #767c90 !important;
  display: inline-block;
}

.feed_comments_sec ul li {
  margin: 0 !important;
}

.feed_comments_sec>ul>li {
  border-bottom: 1px solid #979ba740;
  padding: 10px 0;
}

.feed_comments_sec ul.fcs_reply_sec li h4 {
  color: #040c29;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
}

.feed_comments_sec ul.fcs_reply_sec li p {
  font-size: 12px;
  color: #767c90;
  margin-bottom: 0;
  font-weight: 500;
}

.feed_comments_sec ul.fcs_reply_sec li .des.d-flex p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
  margin-bottom: 0;
  width: 95%;
}

.feed_comments_sec ul.fcs_reply_sec li .user_info h5 {
  color: #767c90;
  font-size: 11px;
  font-style: italic;
}

.feed_comments_sec ul.fcs_reply_sec {
  width: 100%;
  background: #f0f0f0;
  padding: 10px !important;
  border-radius: 10px;
  margin-top: 10px !important;
}

.feed_comments_sec ul.fcs_reply_sec li {
  margin-right: 0 !important;
}

.feed_comments_sec i.fa.fa-ellipsis-h {
  color: #979ba7;
}

.feed_comments_sec .reply_input_sec {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 30px;
}

.feed_comments_sec .reply_input_sec input {
  background: transparent;
  border: none;
  font-size: 12px;
  color: #040c29;
  font-weight: 600;
  width: 90%;
}

.feed_comments_sec .reply_input_sec input::placeholder {
  color: #040c29;
}

.feed_comments_sec .reply_input_sec button.btn {
  padding: 0;
  font-size: 12px;
  color: #4b3b5a;
  font-weight: 600;
}

.astro_feed_sec .feed_comments_sec ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.astro_feed_sec .feed_comments_sec ul .card-header {
  background: transparent;
  border: none;
  padding: 0;
  padding-bottom: 0;
}

.astrologer_feed_area {
  height: 90vh;
}


.form-control:focus {
  /* color: #ffffff; */
  background-color: transparent;
  outline: 0;
  box-shadow: none;
  border-bottom: 1px solid #b3b3b3;
}

.as_login_area .form-control {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none !important;
  color: #ffffff;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
  margin-bottom: 25px;
  position: relative;
}

.form-control::placeholder {
  color: #000000;
}

button.as_btn {
  align-items: center;
  background-color: #2196f3;
  border: none!important;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  height: 30px;
  letter-spacing: .5px;
  margin-left: 15px;
  outline: none;
  position: relative;
  text-transform: uppercase;
  transition: .2s linear;
  width: auto;
}

.header {
  border-bottom: none;
  background-color: #2196f3;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  /* background-image: linear-gradient(343deg,#b2373b,#970625); */
  padding: 8px 20px 8px 8px;
}

.navbar-light .navbar-nav .nav-link {
 color: white !important;
  font-size: 14px;
}

.header .active {
  background: #4b8ab5;
  border-radius: 4px;
}



.bar1 {
  border: 1px solid;
  width: 50%;
  /* margin: 10px;
  align-items: center;
  padding: 5px;
  margin-left: 200px; */
  float: left;

}

.p1 {
  border: 1px solid;
  width: 10%;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background-color: #FBEAFF;
  border: hidden;
}

.p1:hover {
  background-color: #845EC2;
  color: white;
}

.p2 {
  border: 1px solid;
  width: 15%;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background-color: #FBEAFF;
  border: hidden;
}

.p2:hover {
  background-color: #845EC2;
  color: white;
}

.p3 {
  border: 1px solid;
  width: 10%;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background-color: #FBEAFF;
  border: hidden;
}

.p3:hover {
  background-color: #845EC2;
  color: white;
}

/* FacebookPost */

.cardd {
  width: 30.33%;
  float: left;
  margin: 15px;
}

.card-body {
  /* width: 336px;
  height: 455px; */
  text-align: justify;
  /* margin-left: 37px; */

  height: 100%;
  overflow: hidden;
}

.card-img-top {
  /* width: 47%; */
  max-width: 256;
  min-width: 120px;
  height: 300px;
  /* margin-left: 100px; */
  margin-bottom: 15px;
}

.btn {
  color: #040404;
  background-color: #dcdcdc;
  border: none;
  --bs-btn-padding-y: 0.2rem !important;
  margin-top: 10px;
}

.btn:hover {
  /* color: var(--bs-btn-hover-color); */
  color: #040404 !important;
  background-color: #fdf730 !important;
  border-color: var(--bs-btn-hover-border-color);
}

.nav-link {
  margin-left: 10px;
}

.imgclass {
  width: 47%;
  max-width: 256px;
  min-width: 120px;
  height: 249px;
  margin-left: -14px;
  margin-top: 10px;
  margin-bottom: 6px;
}


.dailycardbody {
  width: 95%;
  height: 420px;
  text-align: justify;
  margin-left: 30px;
}

.shadow {
  margin-bottom: 29px;
  /* border-radius: 45px; */
}

.headerdd {
  font-size: 1.75rem;
  margin-bottom: 17px;
  margin-top: 18px;

}

.weeklycardbody {
  width: 95%;
  height: 350px;
  text-align: justify;
  margin-left: 30px;
}

.monthlycardbody {
  width: 95%;
  height: 600px;
  text-align: justify;
  margin-left: 30px;
}

.homepage {
  height: 600px;
  background-position: 100% 50%;
  background-size: cover;
}

.imgastro {
  width: 230px;
  float: left;
  /* margin-left: 23px;
  margin-top: 17px; */
  margin-left: 558px;
  margin-top: 249px;
}

.btnhome {
  color: #212529;
  background-color: #e9ecef3d;
  border-color: #2125298c;
  float: left;
  margin-top: 200px;
  margin-left: -63px;
  border-radius: 5px;
  padding: 8px;
  width: 152px;
}

#bookbtn {
  position: absolute;
  bottom: 28px;
  margin-left: 82px;
}

.festivalcardd {
  /* width: 33.33%; */
  /* float: left; */
  margin: 15px;
}

.blogbody {
  height: 620px;
}

.festivalcardbody {
  height: 500px;
}

/*login_page_css_start*/
.form-group {
  text-align: initial;
}

.form-group {
  text-align: initial;
  margin: 10px 0;
}

span.shownpwd {
  color: #e8ba22;
  cursor: pointer;
  font-size: 20px;
  padding-right: 21px;
  z-index: 99;
  position: absolute;
  right: 0px;
  top: 10px;
}

.as_login_area {
  background-attachment: fixed;
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(150deg, #2196f3, #fdf730);
  background-size: cover;
}

.as_login_area .login-shape {
  position: absolute;
  bottom: -90px;
  left: -90px;
}

.as_login_area .login_inner {
  width: 650px;
  margin: auto;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(255 255 255 / 20%);
  background: rgba(255,255,255,0.2);
  height: auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
   position: absolute;
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);  */
  align-self: flex-center;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  text-align: center;
}

.as_login_area .login_logo img {
  width: 230px;
  margin-bottom: 30px;
  object-fit: contain;
  margin-top: 30px;
}

.as_login_area .login_logo {
  text-align: center;
}

/* .as_login_area .form-control {
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none !important;
  color: #000000;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #221c4a;
  border-radius: 0;

} */

.as_login_area .login_form {
  padding: 0;
}

/*login_page_css_end*/
/* header css start */
.as_header_wrapper {
  display: flex;
  background-color: #07273c;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 99999;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background-image: linear-gradient(343deg, #b2373b, #970625);
  top: 0;
}

.as_header_wrapper .as_logo img {
  object-fit: contain;
}

.as_logo {
  padding: 22px 50px;
  border-right: 1px solid hsla(0, 0%, 100%, .1);
  display: flex;
  align-items: center;
  background: #fff;
}

.as_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.as_menu ul li.login_btn a {
  background: #e8b922;
  padding: 5px 20px !important;
  color: #ffffff !important;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  overflow: hidden;
  position: relative;
}

.as_menu ul li.login_btn a span {
  position: relative;
  z-index: 1;
}

.main-navbar .dropdown-menu {
  left: -80px !important;
  margin-top: 10px;
}




.chat-users-list .chat-user {
  cursor: pointer;
}

.chat-form input {
  width: 90% !important;
  padding: 10px 15px !important;
}

.chat-form button {
  padding: 10px 0;
  width: 105px;
  font-size: 16px;
}


.chat-users-list .chat-user.active {
  color: #fff;
  background-color:#2196f3;

}
.chat-users-list .chat-user .text-time {
  color: black;
}

.chat-users-list .chat-user:hover .text-time {
  color: white;
}

.chat-users-list .chat-user:hover {
  background-color:#2196f3;
}

.chat-users-list .chat-user:hover .user_name h4, .chat-users-list .chat-user:hover .user_name p {
  color: #ffffff;
}


.chat-window {
  border: 1px solid;
}

.chat-header {
  border-bottom: 1px solid;
  padding: 10px 10px;
  background-color: #07273c;
  color: #fff;
}

.chat-header span {
  cursor: pointer;
}

.chat-history {
  padding: 10px 10px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 485px;
}

.event-pointer {
  cursor: pointer;
}

.btnHover:hover {
  color: white !important;
  background-color: #25658f;
}

.pointerEvent {
  cursor: pointer;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.textAreaHeight {
  height: 200px !important;
}

.modalInputs {
  outline: none;
  border-radius: 7px;
}

.modalInputs:focus {
  border: 2px solid #25658f;
}

.adding-pre-wrap{
  white-space: pre-wrap !important;
}

.calLoader {
  position: absolute !important;
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#justify-tab-example-tab-content,
#justify-tab-example-tab-wishes {
  font-weight: 700;
  color: #25658f;
}

.tabs-color-class {
  font-weight: 500;
  color: #25658f;
}

.tabs-color-class:hover {
  color: #585858;
}

.thumbnail-custom-image {
  color: #25658f;
  padding: 3px 5px;
  border-radius: 6px;
  border: 2px solid #25658f;
}

.pointerUtilityClass {
  cursor: pointer;
}

.z_Index {
  z-index: 1000 !important;
}

#inputds {
  display: none;
}


.required:after {
  content: " *";
  color: red;
}

.modal-sm {
  --bs-modal-width: 400px;
}

.model {
  --bs-modal-margin: 4.75rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spinner{
  position: relative;
  top: 50%;
  left: 50%;
}
.spinneruser{
  position: absolute;
  top: 50%;
  left: 50%;
}

/* .form-select {
  padding: 0.925rem 2.25rem 0.375rem 0.75rem !important;
} */

._active {
  background-color: green;
  color: white;
  border: 2px solid green;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;

}

._active:hover {
  border: 2px solid rgb(3, 83, 3);
}

._inactive:hover {
  border: 2px solid brown;
}

._inactive {
  background-color: red;
  color: white;
  border: 2px solid red;
  padding: 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;


}




.Dt-btn {
  background-color: red;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  border: 2px solid red;
  padding: 2px 4px 2px;
  font-weight: 500;
}

.Et-btn:hover {
  border: 2px solid orangered;
}

.Dt-btn:hover {
  border: 2px solid brown;
}


.Et-btn {
  background-color: orange;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  border: 2px solid orange;
  padding: 2px 12px;
  font-weight: 500;
}


.sidenav {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  /* top: 0; */
  left: 0;
  background-color: white;
  overflow-x: hidden;
  padding-top: 20px;
  margin-top: 30px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  margin-top: 4px;
}

.sidenav a:hover {
  color: lightblue;
}

.activel {
  background-color: #25658f;
  color: #fff !important;
  border-radius: 4px;
  margin-left: 8px;
}

.main {
  margin-left: 160px;
  /* Same as the width of the sidenav */
  font-size: 28px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* 
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} */
.sidenav {
  padding-top: 10px;
}

.sidenav a {
  font-size: 16px;
}

#labimg {
  width: 30%;
  display: block !important;
}

#labimg input {
  display: none;
}

.hd-cr{
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  padding: 40px;
}

.grid-container2 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  padding: 40px;
}

.grid-container3 {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  padding: 40px;
}

.grid-container>div {
  background-color: white;
  border: 1px solid rgb(202, 198, 198);
  border-radius: 8px;
  text-align: center;


}

.grid-container2>div {
  background-color: white;
  border: 1px solid rgb(202, 198, 198);
  border-radius: 8px;
  text-align: center;
}

.calicon {
  margin-right: 4px;
}

.newdiv {
margin: 40px;
}

#imgone {
  margin: 30px;
}

.card-body-b {
  padding: 40px !important;
  width: auto !important;
  margin-left: 0 !important;
}

.newdiv1 {
  margin: 60px 240px;
}


.card-img-top-b {
  width: 100%;
  height: 130px !important;
}


.card-img-top-i {
  width: 30% !important;

}

.modal {
  --bs-modal-width: 800px !important;
  --bs-modal-margin: 8.75rem !important;
}


.modal-header {
  color: white !important;
  background-color: #2196f3;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
}

.modal-header-hd {
  background-color: white !important;
  color: #000000 !important;
}

.success_btn {
  background-color: red !important;
  border-color: red;
  color: white;
}

.cancle_btn {
  background-color: lightslategray !important;
  border-color: lightslategray;
  color: white;
}

.add-btn {
  background-color: lightblue;
}
.btn.show {
  color: #000000 !important;
}
.dropselect .btn{
  background-color: white !important;
}

.mrup {
  margin: 10px 0 0 0;
}

.card {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-title-p {
  text-align: center;
  font-size: medium;
}

.card-title {
  text-align: center;
}

.card-text {
  text-align: center;
  font-size: 13px;
}

.textst {
  color: #4d4c4c;
  word-spacing: 5px;
  font-size: medium;

}

.btn-more {
  border: none;
  background-color: white;
  color: blue;
  font-size: large;
}

.btn-more:hover {
  background-color: white !important;
  color: #f39d00 !important;

}

.cal-dat {
  text-align: center;
  font-size: 15px;
}

.by-btn {
  margin-right: 24px;
}
.by-btn-sec {
  margin-right: 24px;
  margin-left: 79%;
}
.left-q {
  margin-left: 4px;
}

.tab-hd-row {
  display: grid;
  grid-template-columns: auto 10% 8%;
  margin-top: 10px;

}

.containerM {
  position: relative;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  color: rgb(144, 120, 120);
  width: 90%;
  text-align: center;
}

.topVIicon {
  position: absolute;
  top: 0;
  margin-left: 10px;
  font-size: 20px;
}

.subtitle-b {
  font-weight: bold;
}

.show_btn {
  background-color: lightblue;
  color: #040c29;
  text-align: right;
  font-size: medium;
  padding: 6px 15px;
  cursor: pointer;
}

.gallery {
  display: flex;
}

.gallery img {
  margin: 10px;
}

.tempFile {
  width: 40%;
  height: 40%;
  margin: 40px 50px;
  border-radius: 8px;
}

.managaldosh-form-style{
  width: 400px !important;
}

.managaldosh-form-style button{
  background-color: #25658f;
  color: white;
}

.managaldosh-form-style input{
  height: 35px;
}

.managaldosh-form-style label {
  font-size: 14px !important;
  color: #7e7d7d;
  margin-bottom: 5px !important;
}
.dt-btn{
  color: red;
}

.hd-list {
  margin: 15px;
  color: #25658f;
}

.hd-list-f {
  color: #25658f;
  margin-top: 10px;

}

.Wc {
  width: 40%;
  padding: 50px;
  border: 1px solid rgb(193, 191, 191);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  margin-top: 25px;
}

.tableWc {

  padding: 50px;
  border: 1px solid rgb(193, 191, 191);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  margin-top: 25px;
}

.imglist {
  width: 15%;
}

/* ========================= */

.pagination>li {
  list-style: none;
  /* border: 0.3px solid; */
}

.pagination>li>a,
.pagination>li>span {
  float: left;
  padding: 8px 20px;
  line-height: 1.5;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination>li.active>a {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}
.pagination>li>a:hover {
  /* background-color:#25658f;
  color: white; */
  cursor: pointer;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
}

.pagination {
  float: right !important;
  margin-right: 20px;
 --bs-pagination-color: black !important;
 --bs-pagination-active-bg:#25658f !important;
}


.chat-search-field{
    position: relative;
}

.chat-bot-search-icon {
  position: absolute;
  left: 14px;
  top: 7px;
}

.chat-search-field .form-input {
  height: 35px;
  width: 100%;
  text-indent: 33px;
  border-radius: 30px;
}

#dropdown-for-userby:hover{
color:black !important;
background-color:transparent !important;
}

.radio-btn-dosh-form{
  padding: 1px 2px;
  border-radius: 5px;
  border: 1px solid #25658f;
  background-color: transparent;
  border-radius: 8px !important;
}

.dosh-btns input[type="radio"]:checked + label {
  background-color: #25658f !important;
  color: white !important;
}

.dosh-btns input[type="radio"]:hover + label {
  background-color: #25658f !important;
  color: white !important;
}

.dosh-response-area{
  height: 100px;
  background-color: #07273c;
  padding: 5px;
  overflow-y: scroll;
}

.chat-context-menu{
  background-color:white;
  color: black;
  width: 180px;
  max-height: 240px;
  overflow-y: scroll;
  padding: 3px 10px;
  border:2px solid black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  z-index: 99;
  flex-direction: column;
}

.chat-context-menu > button{
  color: black;
  background: transparent !important;
  border: none;
  text-align: left;
}

.chat-context-menu > button:hover{
  border-radius: 10px;
  background: black !important;
  color: white;
}


.label-selected-menu {
  box-shadow: none!important;
  font-size: 14px!important;
  font-weight: 500!important;
  height: 30px!important;
  padding: 0 9px!important;
  width: 100%!important;
}
.label-filter-menu{
  background-image: none !important;
  width: 100px !important;
  height: 40px !important;
  padding: 0  10px!important;
  box-shadow: none !important;
}


.label-items-header{
  color: white;
  background-color: #2196f3;
  text-align: center;
  font-weight: 700;
  border-radius: 15px 50px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color:rgb(141, 137, 137) !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color:black !important;
}
.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color:rgb(141, 137, 137) !important ;
}
.was-validated .form-select:valid, .form-select.is-valid {
  border-color:rgb(141, 137, 137) !important ;
}

::placeholder
{
padding-top:8px;
}
.custom-pagination .pagination {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  float: inherit !important;
}
.chat-users-list .custom-pagination {
    padding: 7px 0!important;
    width: 100% !important;
    position: absolute !important;
    bottom: 0;
    background-color: white;
}

.custom-pagination .pagination>li>a {
  padding: 2px 6px !important;
  float: none !important;
  font-size: 12px;
  font-weight: 700;
  margin-right: 3px !important;
  border-color: #2196f3;
  color: #2196f3;
}

.custom-pagination .pagination .previous a {
  padding: 2px 3px !important;
  color: #2196f3;
  margin: 0 3px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  border-color: #2196f3 !important;
}
.custom-pagination .pagination .next a {
  padding: 2px 3px !important;
  color: #2196f3;
  margin: 0 0px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  border-color: #2196f3 !important;
  margin-right: 0 !important;
}

.custom-pagination .pagination a:hover {
    background-color: #2196f3 ;
    color: #ffffff;

}  

/* chat_windows */

.chat_window {
    padding: 0;
}

.chat_window > .row {
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.chat-users-list {
  border-right: 1px solid;
  min-height: calc(100vh - 55px);
  padding: 0;
  position: relative;
}

.chat-users-list .user_list_header{
  padding: 10px 10px;
  background: #ededed;
}

.chat_search_box {
  background: #f7f7f7;
  padding: 5px 10px;
}

.chat_all_users {
  height: calc(100vh - 260px);
  overflow-y: auto;
  position: relative;
}
.chat-users-list .chat-user {
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid #ededed;
}

.chat-users-list .chat-user .user_name h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px !important;
}

.chat-users-list .chat-user  .user_name p {
  font-size: 14px;
  color: #7f7575;
  font-weight: normal;
  margin: 0;
}

.chat-users-list .chat-user .user_info svg {
  margin-top: 2px;
}

.Time {
  position: absolute;
  right: 7px;
  bottom: 0;
}

.Time span {
  color: #818181;
  font-size: 10px;
}


.chat-users-list .chat-user.active .user_name h4, .chat-users-list .chat-user.active  .user_name p{
  color:#ffffff
}
.chat-users-list .chat-user.active .Time span {
  color: #ffffff;
  font-size: 12px;
}

.main-logo {
  color: #fdf730;
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 1px 0;
}

.chat-section .chat-window {
  border: none;
  min-height: calc(100vh - 157px);
}

.chat-section .chat-window .chat-header {
  border-bottom: none;
  padding: 10px 10px;
  background-color: #fdf730;
  color: #000;
  font-weight: 600;
}

.chat-section .chat-window .bot-right {
  background: #dcf8c7 !important;
  color: #000;
  border-radius: 20px !important;
  border-bottom-right-radius: 0 !important;
  text-align: left !important;
  width: 45% !important;
  padding: 20px !important;
}

.chat-section .chat-window .user-left {
  padding: 15px !important;
  border-radius: 20px !important;
  background: #ffffff !important;
  border-bottom-left-radius: 0 !important;
}

.chat-history {
  padding: 10px 10px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 157px);
}

button#dropdown-for-userby {
  font-weight: 600;
  position: relative;
  top: -2px;
}
form.chat-form.d-flex.d-block {
  background: #ededed;
  display: flex !important;
  align-items: center;
  height: 58px;
  justify-content: space-between;
  padding: 0 20px;
}

form.chat-form textarea {
  width: 90%;
  border: 1px solid #dee2e6 !important;
  height: 40px;
  color: #000000;
  padding: 5px 15px !important;
  line-height: normal;
  
}

 form.chat-form textarea::placeholder {
    padding-top: 0px;
}

form.chat-form button.border.as_btn.send {
  text-align: center !important;
  color: #ffffff !important;
  justify-content: center;
  width: auto;
  height: 40px;
  padding: 0 6px;
  padding-right: 15px;
}

.download_icon label:hover{
  background-color: #2196f3;
}

.download_icon label:hover svg{
  color: #ffffff !important;
}



form.chat-form .download_icon svg {
  font-size: 32px;
  padding: 5px;
  padding-top: 7px;
  margin-bottom: 0;
  color: #2196f3;
  font-weight: 800 !important;
}

label.radio-btn-dosh-form {
  background: #2196f3;
  color: #ffffff;
  border: none;
  border-radius: 5px !important;
  padding: 6px 4px !important;
}

.chat-search-field input.form-control.form-input{
  color:#000;
}

.chat-search-field input.form-control.form-input::placeholder {
  color: black;
}

.user_list_header .fw-bold.me-1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
span.user_issue {
  background: red;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-style: normal;
  margin-left: 7px;
  padding: 2px 5px;
}

/* header */

.header a.navbar-brand {
  background: #ffffff;
  height: 70px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
  padding: 8px 15px;
  position: relative;
  font-size: 15px;
  transition: all .3s linear;
  margin-left: 0;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fdf730 !important;
  background: transparent;

}

.navbar-light .navbar-nav .nav-link.active {
  color: #fdf730 !important;
  background: transparent !important;

}
.navbar-light .navbar-nav .nav-link:before{
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    background-color:#fdf730;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
}

.navbar-light .navbar-nav .nav-link.active:before, .navbar-light .navbar-nav .nav-link:hover:before {
  width: 35px;
}

/* feed_area */
.feed_area {
  position: relative;
  width: 100%;
  padding-top: 50px;
}

.feed_box.card {
  overflow: hidden;
  margin-bottom: 30px;
}
.feed_box.card .card-header {
  position: relative;
  padding: 0;
  width: 100%;
}

.feed_box.card .card-header .user_img {
  width: 100%;
  object-fit: cover;
  height: 270px;
  object-position: center;
}

.feed_box.card .card-header  .trash_top {
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  padding: 0;
}

.feed_box.card .card-header .trash_top .serial_no {
  background: #e8ba22;
  color: #fff;
  margin-top: 8px;
  padding: 0 7px;
  border-radius: 0 15px 15px 0px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 13px;
  line-height: 26px;
  left: 0;
}

.feed_box.card .card-header .trash_top  span.icon {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 9px;
  margin-right: 7px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  margin-top: 10px;
}

.feed_box.card  .card-body .info h6 {
  font-size: 18px;
  color: #040c29;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px !important;
  display: block;
}

.feed_box.card  .card-body  .info>p {
  color: #767c90;
  font-size: 14px;
  margin-bottom: 5px;
}

.feed_box.card .card-body .text_description>p {
  color: #767c90;
  font-size: 14px;

}

.feed_box.card  .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feed_box.card  .text_description {
  height: 200px;
  overflow-y: scroll;
  padding-right: 10px;
  margin-bottom: 10px;
}

.feed_box.card .card-footer .cursor-pointer{
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 600;
}

.feed_box.card .card-footer .cursor-pointer button{
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 600;
}

.feed_box.card .card-footer .cursor-pointer span {
  font-size: 12px;
  font-weight: 600;
}

.feed_box.card .card-footer .cursor-pointer svg {
  margin-right: 5px;
}

/* gita_qoute */
.gita_quote_heading {
  background: #fff;
  height: clamp(73px,73px,73px);
  align-items: center;
  box-shadow: 0 0 10px rgba(0,0,0,.2);

  display: flex;
}

.gita_quote_heading button.as_btn {
  height: 45px;
  background-color: #2196f3;
  font-size: 16px;
  padding: 0px 14px;
  position: relative;
  margin-left: 0;
 
}

.gita_quote_heading .hd-list-f {
  color: #4b3b5a;
  margin: 0;
}

.admin_grid_table .table > thead tr th {
  background: #a21a2e;
  color: #ffffff;
  font-size: 14px;
  text-align: left !important;
  padding: 12px 15px;
}

.admin_grid_table table.table {
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 10px !important;
  background: #ffffff;
}
.admin_grid_table table.table tbody tr td {
  vertical-align: middle;
  color: #767c90 !important;
  font-size: 14px;
  padding: 5px 15px;
}

.admin_grid_table table.table tbody tr td:nth-child(2) {
  width: 68%;
  margin-right: 30px !important;
}


.admin_grid_table table.table tbody tr td:nth-child(6) {
  width:150px;

}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #a21a2e;
  border-color: #a21a2e;
}

/* aarti section */

.main.aarti_section {
  margin-left: 0;
  font-size: 28px;
  padding: 0;
}

.main.aarti_section .sidenav {
  height: calc(100vh - 200px);
  width: 100%;
  position: sticky;
  top: 9px;
  z-index: 1;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 0px;
  margin-top: 0px;
  padding: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.main.aarti_section .sidenav a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  margin: 0 !important;
  border-top: 1px solid #ededed;
}
.main.aarti_section .sidenav a.activel {
  background-color: #9d102a;
  color: #fff !important;
  border-radius: 4px;
  margin-left: 8px;
}


.aarti_inner .card .card-header {
  position: relative;
  width: 100%;
  border: none;
  height: 250px;
}

.aarti_inner .card .card-header img {
  object-fit: cover;
  height: 235px;
}

.aarti_inner .card .card-header  .icon {
  position: absolute;
  top: 0;
  right: 24px;
  background: #ffffff;
  border-radius: 30px;
  padding: 0 15px !important;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}



.height_box {
  min-height: 220px;
}

.download_icon {
  position: relative;
}

.download_icon  span.cross svg {
  font-size: 14px !important;
  padding: 0 !important;
}
.download_icon span.cross {
  position: absolute;
  right: -8px;
  top: -6px;
}
.img-fluid-log {
  max-width: 50% !important;
}

.Multiple_button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Multiple_button button.as_btn {
  align-items: center;
  background-color: #2196f3;
  border: none!important;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  font-weight: 500;
  height: 26px;
  margin-left: 15px;
  outline: none;
  position: relative;
  text-transform: capitalize;
  transition: .2s linear;
  width: auto;
}

.Multiple_button button.as_btn:hover {
  background-color: #fdf730 !important;
  color: black !important;
}


@media only screen and (max-width: 1399px) {
  .label-selected-menu {
    box-shadow: none!important;
    font-size: 13px!important;
    font-weight: 500!important;
    height: 30px!important;
    padding: 0 10px!important;
    width: 100%!important;
}
}

@media only screen and (max-width: 767px) {
.as_login_area .login_inner {
  width: 95%;
 
}

.as_login_area .img-fluid-log {
  max-width: 100%;
  width: 200px;
}

.chat_window {
  position: relative;
}

.chat_window .chat-section.col-xl-9.col-lg-8.col-md-8 {
  position: absolute;
  left: 0;
  width: 100%;
}

.chat-users-list {
  border-right: none;
 
}

.managaldosh-form-style {
  width: 100% !important;
}

.main-logo {
  font-size: 17px;
}



}
@media only screen and (max-width: 440px) {

  .chat-section .chat-window .chat-header {
  border-bottom: none;
  padding: 10px 10px;
  background-color: #ededed;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: start;
  flex-direction: column-reverse;
  position: relative;
}

.chat-section .chat-window .chat-header span.float-end.d-flex.me-3 {
  float: initial !important;
  margin-top: 8px;
}

.chat-section .chat-window .chat-header span.float-end.me-2 {
  position: absolute;
  right: 5px;
  top: 11px;
}

}